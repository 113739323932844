import React from 'react';
import styled from 'styled-components';
import { PrimaryIcon, SEO } from '../components';
import Layout from '../components/Layout/Layout';
import { Puzzle } from '../components/Puzzle';
import { rem, theme } from '../theme';

const AboutSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  padding: 48px 32px;

  @media only screen and (min-width: ${theme.breakpoints[2]}px) {
    padding: 48px 148px;
  }

  ${PrimaryIcon} {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: ${theme.breakpoints[3]}px) {
    flex-direction: column;
  }
  width: 100%;
  justify-content: center;
  & > svg {
    margin: ${rem(1)};
  }
`;

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  & > svg {
    margin: 0 ${rem(1)} ${rem(1)} 0;
    width: auto;
    height: 40px;
  }

  @media only screen and (min-width: ${theme.breakpoints[3]}px) {
    & > svg {
      width: auto;
      height: 70px;
    }
  }
`;
const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  font-size: ${rem(2)};
  text-align: justify;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  & > a {
    color: ${theme.colors.topbar.link_active_button} !important;
    text-decoration: none !important;
    font-size: ${rem(2)};
    display: contents;
  }
  & > b {
    display: contents;
  }
  margin: 0px;
`;

const TextColumnRight = styled(TextColumn)`
  @media only screen and (min-width: ${theme.breakpoints[2]}px) {
    padding-left: ${rem(1)};
  }
`;

const TextColumnLeft = styled(TextColumn)`
  @media only screen and (min-width: ${theme.breakpoints[2]}px) {
    padding-right: ${rem(1)};
  }
`;
const RedBox = styled.div`
  background-color: ${theme.colors.topbar.link_active_button} !important;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  padding: 48px 48px;
  font-size: ${rem(3)};
  font-weight: ${theme.fontWeights[700]};
  line-height: 20px;

  & > div {
    width: 100%;
    text-align: center;
  }

  @media only screen and (min-width: ${theme.breakpoints[2]}px) {
    padding: 48px 148px;
  }
`;

const PartnersPage = () => {
  return (
    <Layout>
      <SEO title="Partners" />
      <AboutSection>
        <TextColumn>
          <b>B4 Limits Sp. z o.o.</b> and{' '}
          <a href="https://www.sourcefactory.pl">sourcefactory Sp. z o.o.</a>{' '}
          cooperate closely together in the area of near-shoring of engineering
          services for clients from around the world. The two companies share a
          common office space with{' '}
          <a href="https://www.komunikacjapolska.com/">
            Komunikacja Polska Sp. z o.o.
          </a>{' '}
          which fulfils the role of a business hub that holds the companies
          together.
        </TextColumn>
        <TextRow>
          <TextColumnLeft>
            <TextColumn>
              <b>B4Limits</b> is a hardware & firmware engineering company with
              software capabilities <b>ex-Switzerland</b> while sourcefactory is
              a Swiss market focused software-house. The concept of company
              co-habitation arose from differing shareholder needs and
              requirements as well as desire for our team-members and our
              clients to have <b>best suited engineering power</b> for (Y)our
              projects. In this way, the team-members can move between projects
              and work on behalf of the companies as we (you and we) see fit
              allowing for the{' '}
              <b>greatest amount of flexibility and balance for both</b> - our
              client(s) and our team members.
            </TextColumn>
            <TextColumn>
              <b>sourcefactory</b> is a proud member of the{' '}
              <a href="https://swisschamber.pl/">
                Polish-Swiss Chamber of Commerce
              </a>{' '}
              while <b>Komunikacja Polska</b> is a equally proud member of the{' '}
              <a href="https://nlchamber.com.pl/">
                Netherlands-Polish Chamber of Commerce
              </a>
              . All companies support and work with{' '}
              <a href="https://www.peoplematterfoundation.com/">
                People Matter Foundation
              </a>{' '}
              which <b>helps talented teens from challenging environments</b> to
              reach their potential.
            </TextColumn>
          </TextColumnLeft>
          <TextColumnRight>
            <Puzzle />
          </TextColumnRight>
        </TextRow>
      </AboutSection>
    </Layout>
  );
};

export default PartnersPage;
